<!--
 * @Author: 卢均锐morye
 * @Date: 2020-04-19 09:53:00
 * @LastEditTime: 2020-04-24 19:24:06
 -->
<template>
	<div v-loading="loading" size="none" class="staff" style="padding: 20px; background-color: #fff; overflow: hidden">
		<el-form inline :model="searchForm" class="demo-form-inline" size="small">
			<el-form-item label="培训课程">
				<el-select placeholder="请选择培训课程" filterable clearable size="small" v-model="searchForm.trainingCourseId">
					<el-option v-for="item in list2" :key="item.id" :value="item.id" :label="item.trainName"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="培训时间">
				<el-date-picker
					v-model="searchForm.trainingStartDate"
					size="small"
					default-time="00:00:00"
					type="date"
					value-format="yyyy-MM-dd HH:mm"
					format="yyyy-MM-dd"
					clearable
				></el-date-picker>
				<span style="margin: 0 5px">-</span>
				<el-date-picker
					v-model="searchForm.trainingEndDate"
					size="small"
					default-time="23:59:59"
					type="date"
					value-format="yyyy-MM-dd HH:mm"
					format="yyyy-MM-dd"
					clearable
				></el-date-picker>
			</el-form-item>
			<el-form-item label="报名时间">
				<el-date-picker
					v-model="searchForm.signUpStartDate"
					size="small"
					default-time="00:00:00"
					type="date"
					value-format="yyyy-MM-dd HH:mm:ss"
					format="yyyy-MM-dd"
					clearable
				></el-date-picker>
				<span style="margin: 0 5px">-</span>
				<el-date-picker
					v-model="searchForm.signUpEndDate"
					size="small"
					default-time="23:59:59"
					type="date"
					value-format="yyyy-MM-dd HH:mm:ss"
					format="yyyy-MM-dd"
					clearable
				></el-date-picker>
			</el-form-item>
			<el-form-item label="已读状态">
				<el-select v-model="searchForm.readStatus" placeholder="" clearable>
					<el-option label="未读" value="0"></el-option>
					<el-option label="已读" value="1"></el-option>
				</el-select>
			</el-form-item>
			<el-button type="primary" size="small" style="margin-left: 5px" icon="el-icon-search" @click="_getList(1)">查询</el-button>
		</el-form>
		<el-table :data="list" stripe @sort-change="_sortChange">
			<el-table-column type="index" align="center" label="序号" width="50"></el-table-column>
			<el-table-column
				v-for="(col, i) in columns"
				:prop="col.prop"
				:key="i"
				:width="col.prop == 'trainingStartTime' || col.prop == 'signUpStartDate' ? '160px' : ''"
				align="center"
				:label="col.label"
				:sortable="col.prop == 'trainingStartTime' || col.prop == 'signUpStartDate' ? 'custom' : false"
			>
				<template v-slot="{ row }" v-if="col.prop == 'trainingCourse' || col.prop == 'trainingPlace'">
					<div class="coursePlace">{{ row[col.prop] }}</div>
				</template>
			</el-table-column>
			<el-table-column prop="readStatus" label="已读状态" align="center">
				<template v-slot="{ row }">
					<div>{{ row.readStatus == '0' ? '未读' : row.readStatus == '1' ? '已读' : '' }}</div>
				</template>
			</el-table-column>
			<el-table-column align="center" width="130px" label="操作" fixed="right">
				<template slot-scope="scope">
					<el-button type="text" @click="_signUp(scope.row)" v-if="!scope.row.signUpStartDate && _returnSignStatus(scope.row)"
						>报名</el-button
					>
					<el-button type="text" @click="_toDetail(scope.row)">详情</el-button>
				</template>
			</el-table-column>
		</el-table>
		<el-pagination
			style="margin: 20px 0; text-align: right"
			@size-change="_handleSizeChange"
			@current-change="_handleCurrentChange"
			:current-page="searchForm.pageNo"
			:page-sizes="[20, 50, 100, 200]"
			:page-size="searchForm.pageSize"
			layout="total, sizes, prev, pager, next, jumper"
			:total="searchForm.total"
		></el-pagination>
	</div>
</template>
<script>
import { staffTrainingList, trainingName, signUp } from '@/api/trainMange.js';
export default {
	data() {
		return {
			loading: false,
			dateRange: '',
			dateRange2: '',
			list: [],
			list2: [],
			searchForm: {
				trainingStartDate: '',
				trainingEndDate: '',
				signUpStartDate: '',
				readStatus: '',
				signUpEndDate: '',
				trainingCourseId: '',
				trainingStartDateSort: 'desc',
				signUpDateSort: '',
				pageNo: 1,
				pageSize: 20,
				total: 0
			},
			columns: [
				{ label: '培训课程', prop: 'trainingCourse' },
				{ label: '培训讲师', prop: 'trainingInstructors' },
				{ label: '培训形式', prop: 'trainingForm' },
				{ label: '培训地点', prop: 'trainingPlace' },
				{ label: '培训时间', prop: 'trainingStartTime' },
				{ label: '考核结果', prop: 'accessScore' },
				{ label: '报名时间', prop: 'signUpStartDate' }
			]
		};
	},
	methods: {
		_handleSizeChange(val) {
			this.searchForm.pageSize = val;
			this._getList();
		},
		_handleCurrentChange(val) {
			this.searchForm.pageNo = val;
			this._getList();
		},
		_sortChange({ prop, order }) {
			if (prop == 'trainingStartTime') {
				if (order == 'ascending') {
					this.searchForm.trainingStartDateSort = 'asc';
					this.searchForm.signUpDateSort = '';
				} else if (order == 'descending') {
					this.searchForm.trainingStartDateSort = 'desc';
					this.searchForm.signUpDateSort = '';
				} else {
					this.searchForm.trainingStartDateSort = '';
				}
			} else if (order == 'ascending') {
				this.searchForm.signUpDateSort = 'asc';
				this.searchForm.trainingStartDateSort = '';
			} else if (order == 'descending') {
				this.searchForm.signUpDateSort = 'desc';
				this.searchForm.trainingStartDateSort = '';
			} else {
				this.searchForm.signUpDateSort = '';
			}
			this._getList();
		},
		_getList(page) {
			page ? (this.searchForm.pageNo = page) : '';
			this.loading = true;
			staffTrainingList(this.searchForm).then((res) => {
				this.loading = false;
				this.list = res.list;
				this.searchForm.total = res.totalNum;
			});
		},
		_signUp(row) {
			this.$confirm(`您确认要报名${row.trainingCourse}培训课程吗？`, '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			})
				.then(() => {
					this.loading = true;
					signUp({ id: row.id, trainingCourseId: row.trainingCourseId }).then((res) => {
						this.loading = false;
						if (res.code == 0) {
							this.$message.success('报名成功');
							this.$parent.queryBadge();
							this._getList();
						}
					});
				})
				.catch(() => {});
		},
		_dateRangeChange(val) {
			if (val) {
				this.searchForm.trainingStartDate = val[0];
				this.searchForm.trainingEndDate = val[1];
			} else {
				this._clear();
			}
		},
		_clear() {
			this.dateRange = '';
			this.searchForm.trainingStartDate = '';
			this.searchForm.trainingEndDate = '';
		},
		_dateRangeChange2(val) {
			if (val) {
				this.searchForm.signUpStartDate = val[0];
				this.searchForm.signUpEndDate = val[1];
			} else {
				this._clear2();
			}
		},
		_clear2() {
			this.dateRange2 = '';
			this.searchForm.signUpStartDate = '';
			this.searchForm.signUpEndDate = '';
		},
		_getNameList() {
			trainingName({}).then((res) => {
				this.list2 = res;
			});
		},
		_toDetail(row) {
			this.$router.push({
				name: 'TrainDetail',
				query: { type: 3, id: row.id, trainingCourseId: row.trainingCourseId, time: row.signUpStartDate }
			});
		},
		_returnSignStatus(row) {
			if (new Date(row.trainingStartTime).getTime() > new Date().getTime()) {
				return true;
			}
			return false;
		}
	},
	mounted() {
		this._getNameList();
		this._getList();
	}
};
</script>
<style lang="scss" scoped>
::v-deep.el-form--inline .el-form-item {
	vertical-align: initial;
}
::v-deep.el-form-item__label {
	font-weight: normal;
}
::v-deep.el-table th,
.el-table tr {
	background-color: #f0f8ff;
}
.coursePlace {
	width: 100%;
	white-space: normal;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 2;
}
</style>
